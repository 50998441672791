import React from "react";
import { toggleConditionModal } from "@redux/actions/termsPolicy";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { terms, policy, consent } from "@components/containers/rules/constants";

const TermsPolicy = () => {
  const dispatch = useDispatch();
  const { type } = useSelector((state: RootState) => state.Conditions);
  return (
    <div className="fixed z-50 bg-white w-screen h-screen top-0 left-0 flex flex-col ">
      <div className="flex w-full items-center justify-between py-6 px-4">
        <img
          className="lg:w-40 lg:h-12 w-36 mr-2 xl:mr-0 "
          alt="RemoteDerm Logo"
          src={"https://cdn.remotederm.ca/images/logo_text.webp"}
        />
        <div
          className="cursor-pointer h-10 w-10 flex items-center justify-center rounded-full"
          onClick={() => toggleConditionModal("")(dispatch)}
        >
          <svg
            className="MuiSvgIcon-root"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </div>
      </div>
      <div className="flex w-full md:w-4/5 lg:w-3/5 h-full mx-auto px-6 pb-2">
        <div className="flex flex-col terms-section overflow-hidden bg-white rounded-3xl border-color-c46182 border">
          <div className="flex w-full h-20 justify-center items-center shadow-lg font-sans-pro-regular color-150910-secondary-1 font-size-24">
            {type === "terms"
              ? `Terms & Conditions`
              : type === "policy"
              ? `Privacy & Policy`
              : `RemoteDerm CONSENT TO USE ELECTRONIC COMMUNICATIONS`}
          </div>
          <div className="flex flex-1 flex-col overflow-scroll py-6 px-4">
            {type === "terms" ? (
              <>
                {terms.map((item, index) => (
                  <div key={index} className="flex flex-col">
                    <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24 mb-3">
                      {item.title}
                    </span>
                    <p className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6">
                      {item.p}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <>
                {type === "policy" ? (
                  <>
                    {policy.map((item, index) => (
                      <div key={index} className="flex flex-col">
                        <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24 mb-3">
                          {item.title}
                        </span>
                        {item.p.map((option, indexJ) => (
                          <p
                            key={indexJ}
                            className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6"
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {consent.map((item, index) => (
                      <div key={index} className="flex flex-col">
                        <span className="font-sans-pro-regular color-150910-secondary-1 font-size-24 mb-3">
                          {item.title}
                        </span>
                        <p className="font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-6">
                          {item.p}
                        </p>
                        {item.options?.map((option, indexJ) => (
                          <p
                            key={indexJ}
                            className="ml-3 font-sans-pro-regular color-636363-secondary-2 font-size-14 mb-2"
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPolicy;

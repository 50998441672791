export const terms = [
  {
    title: "Acceptance",
    p: [
      `It is important that you read all the following terms and conditions carefully.`,
      `This Terms of Use Agreement (“Agreement”) is a legal agreement between you and RemoteDerm ("the Service"). If you are under fourteen (14) years of age, the person with your parental authority would be considered the user and shall be referred to as “You”.`,
      `This Agreement states the terms and conditions under which you may access and use the Service and all written and other materials displayed or made available through the Service, including, without limitation, articles, text, photographs, images, illustrations, audio clips, video clips, computer software, and code (the “Content”).`,
      `By accessing and using the Website, you are indicating your acceptance to be bound by the terms and conditions of this Agreement. If you do not accept these terms and conditions, you must not access or use the Service.`, 
      `RemoteDerm may revise this Agreement at any time by updating this page. Use of the Service after such changes are posted and will signify your acceptance of these revised terms. You should visit this page periodically to review this Agreement.`
    ]
  },
  {
    title: "Medical Emergency",
    p: [
      `Do NOT use the Service for medical emergencies. If you have a medical emergency, call a physician or qualified healthcare provider, or CALL 911 immediately. Under no circumstances should you attempt self-treatment based on anything you have seen or read on the Website.`
    ]
  },
  {
    title: "General Information Is Not Medical Advice",
    p: [
      `The general information provided on the Service's website and blog is for informational purposes only and is not professional medical advice, diagnosis, treatment, or care, nor is it intended to be a substitute therefore.`,
      `Always seek the advice of your physician or other qualified health provider properly licensed to practice medicine or general healthcare in your jurisdiction concerning any questions you may have regarding any information obtained from this Service's website and blog and any medical condition you believe may be relevant to you or to someone else.`,
      `Never disregard professional medical advice or delay in seeking it because of something you have read on this Service's website and blog. Always consult with your physician or other qualified healthcare provider before embarking on a new treatment, diet, or fitness program.`,
      `Information obtained on the Service's website and blog is not exhaustive and does not cover all diseases, ailments, physical conditions, or their treatment.`
    ]
  },
  {
    title: "No Physician-Patient Relationship",
    p: [
      `The presentation of general information on the Service's website does not establish a physician-patient relationship between you and any of its physicians and is not intended as a solicitation of individuals to become patients or clients of any of its physicians.`,
      `This section does not apply to the extent that you are using the Service's website to communicate with a physician with whom you have an existing physician-patient relationship.`
    ]
  },
  {
    title: "No Endorsements",
    p: [
      `Unless specifically stated, the Service does not recommend or endorse any specific brand of products, services, procedures, or other information that appears or that may be advertised on the Service's website and blog.`
    ]
  },
  {
    title: "Patient Portal Services",
    p: [
      `If you are an existing patient, the Service's tele-dermatology platform located at patient.remotederm.ca ("the Platform"), may allow you to communicate with the Service's administration and your physician(s) by email or by some other secure electronic means.`,
      `Communications sent by you to the Service's administration or your physician, or to you by the Service's administration or your physician via email or outside of the Platform may not be secure and may be accessible by third parties. Any use or disclosure of personal information provided by you shall be in accordance with our privacy policy.`,
      `The Service may issue to you a username (“Username”) and a password (“Password”) to permit you to access the the Platform portion of the Service. You are solely responsible for the confidentiality and use of your Username and Password.`
    ]
  },
  {
    title: "Email Communication with the Public",
    p: [
      `RemoteDerm and its administration team do not wish to use this Service's website as a means of communication with the public (i) regarding questions or issues of a medical nature; (ii) to establish physician-patient relationships; (iii) to book or cancel appointments; or (iv) for inquiries regarding fees, services, or similar matters.`,
      `Email communications regarding such matters will not be responded to and will be discarded unread. If you are not an existing patient and you wish to contact RemoteDerm and its administration team or physicians regarding medical questions or issues or with regard to appointments, accounts, or other questions, please do so by options available to you in our <a href="/pages/contact-us">contact-us</a> page.`
    ]
  },
  {
    title: "Disclaimer of Warranties",
    p: [
      `The Service's website and the Content are provided “AS IS” and “AS AVAILABLE.” While RemoteDerm and its administration team endeavour to provide information that is correct, accurate, current, and timely, the RemoteDerm and its administration team make no representations, warranties, or covenants, express or implied, regarding the Service's website and the Content including, without limitation, no representation, warranty, or covenant that (i) the Content contained in or made available through the Service's website or any item(s) made available on or through the Service's website will be of merchantable quality and/or fit for a particular purpose; (ii) the Service's website or Content will be accurate, complete, current, reliable, timely, or suitable for any particular purpose; (iii) that the operation of the Service will be uninterrupted or error-free; (iv) that defects or errors in the Service or the Content, be it human or computer errors, will be corrected; (v) that the Service will be free from viruses or harmful components; and (vi) that communications to or from the Service will be secure and/or not intercepted. You acknowledge and agree that your access and use of the Service and the Content is entirely at your own risk and liability.`
    ]
  },
  {
    title: "Limitation of Liability",
    p: [
      `In no event shall RemoteDerm and its administration team, its physicians, officers, directors, employees, agents, licensors, and their respective successors and assigns be liable for damages of any kind, including, without limitation, any direct, special, indirect, punitive, incidental, or consequential damages including, without limitation, any loss or damages in the nature of, or relating to, lost business, medical injury, personal injury, wrongful death, improper diagnosis, inaccurate information, improper treatment, or any other loss incurred in connection with your use, misuse, or reliance upon the Service or the Content, or your inability to use the Service, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if RemoteDerm and its administration team knew of or ought to have known of the possibility of such damages.`,
      `RemoteDerm and its administration team also expressly disclaims any and all liability for the acts, omissions, and conduct of any third-party user of the Service, or any advertiser or sponsor of the Service (“third-party”). Under no circumstances shall RemoteDerm and administration team, its physicians, officers, directors, employees, agents, licensors, and their respective successors and assigns, be liable for any injury, loss, damage (including direct, special, indirect, punitive, incidental, or consequential damages), or expense arising in any manner whatsoever from (i) the acts, omissions, or conduct of any third-party; and (ii) any access, use, reliance upon, or inability to use any materials, content, goods, or services located at, or made available at, any Service linked to or from the Service, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if RemoteDerm and its administration team knew of or ought to have known of the possibility of such damages.`
    ]
  },
  {
    title: "Indemnity",
    p: [
      `You agree to indemnify, defend, and hold harmless RemoteDerm and its physicians, officers, directors, employees, agents, licensors, and their respective successors and assigns, from and against any and all claims, demands, liabilities, costs, or expenses whatsoever, including, without limitation, legal fees and disbursements, resulting directly or indirectly from (i) your breach of any of the terms and conditions of this Agreement; (ii) your access to, use, misuse, reliance upon, or inability to access or use the Service, the Content, or any Service to which the Service is or may be linked to from time to time or; (iii) your use of, reliance on, publication, communication, distribution, uploading, or downloading of anything (including the Content) on or from the Service.`
    ]
  },
  {
    title: "Use of the Service",
    p: [
      `RemoteDerm authorizes you to access and use the Service for your personal non-commercial use in Canada in accordance with the terms and conditions of this Agreement. You acknowledge and agree that the Service, and its contents, are intended only for residents of Canada.`
    ]
  },
  {
    title: "Copyright",
    p: [
      `The Content is protected by copyright law and is owned by RemoteDerm and its licensors, or the party accredited as the provider of the Content. Except as granted in the limited license herein, any use of the Content, including modification, transmission, presentation, distribution,republication, or other exploitation of the Service or of its Content, whether in whole or in part, is prohibited without the express prior written consent of the Owner.`
    ]
  },
  {
    title: "Limited License",
    p: [
      `Subject to the terms and conditions of this Agreement, you are hereby granted a limited, non- transferable, and nonexclusive license to access, view, and use the Service and the Content for your personal, non-commercial use. You are granted the right to download, store, and/or print single copies of items comprising the Content for your personal, non-commercial use, provided that you maintain all copyright and other notices contained in such Content. You may not copy and/or post items comprising the Content online. You must also abide by any additional requirements governing the use of any specific Content that may be set out in the Service.`,
      `In the event of a conflict between the terms of a license governing specific Content and this Agreement, the terms of the specific license shall govern.`
    ]
  },
  {
    title: "Trademarks",
    p: [
      `RemoteDerm is a trademark of RemoteDerm LLC. Other names, words, titles, phrases, logos, designs, graphics, icons, and trademarks displayed on the Service may constitute registered or unregistered trademarks of RemoteDerm LLC or third parties. While certain trademarks of third parties may be used by RemoteDerm LLC under license, the display of third-party trademarks on the Service should not be taken to imply any relationship or license between RemoteDerm and the owner of the trademark or to imply that RemoteDerm endorses the wares, services, or business of the owner of the said trademark.`
    ]
  },
  {
    title: "Linking",
    p: [
      `The Service contains links to third-party Services. These links are provided solely as a convenience to you and not as an endorsement by RemoteDerm of any third-party Service or the content thereof.`,
      `Unless expressly stated, RemoteDerm does not operate any third-party services linked to the Service's website and is not responsible for the content of any third-party Service, nor does it make any representation, warranty, or covenant of any kind regarding any third-party Service including, without limitation, (i) any representation, warranty, or covenant regarding the legality, accuracy, reliability, completeness, timeliness, or suitability of any content on such third-party Service(s); (ii) any representation, warranty, or covenant regarding the merchantability and/or fitness for a particular purpose of any third-party Service(s) or material, content, software, goods, or services located at or made available through such third-party Service(s); or (iii) any representation, warranty, or covenant that the operation of such third-party Service(s) will be uninterrupted or error free, that defects or errors in such third-party Service(s) will be corrected, or that such third-party Service(s) will be free from viruses or other harmful components.`,
      `While RemoteDerm encourages links to the Service's website, it does not wish to be linked to or from any third-party Service(s) which contains, posts, or transmits any unlawful or indecent information of any kind, including, without limitation (i) any content constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, provincial, territorial, national, international law or regulation which may be damaging or detrimental to the activities, operations, credibility, or integrity of the Owner; or (ii) any Service website which contains, posts, or transmits any material or information of any kind which violates or infringes upon the rights of others, including material which is an invasion of privacy or publicity rights, or which is protected by copyright, trademark, or other proprietary rights.`,
      `The Owner reserves the right to prohibit or refuse to accept any link to the Service's website, including, without limitation, any link which contains or makes available any content or information of the foregoing nature, at any time. You agree to remove any link you may have to the Service's website upon the request of RemoteDerm or any of its administrative staff.`
    ]
  },
  {
    title: "Submissions",
    p: [
      `The Service's website may provide features which allow you to post messages and content to designated areas on the Service's website, to participate in chat groups, to interact with RemoteDerm staff and other users and to upload files, documents, or other materials (“Submission(s)”). Submissions do not include communications between you and your physician pursuant to the physician-patient relationship.`,
      `RemoteDerm does not control the content of any Submissions and has no obligation to monitor the Submissions. However, RemoteDerm reserves the right at all times to disclose any information necessary to satisfy any law, regulation, or governmental or law enforcement request, or to edit, refuse to post, or refuse to remove any Submission, in whole or in part, that, in the RemoteDerm’s sole discretion, are objectionable or in violation of this Agreement.`,
      `You acknowledge that you alone are responsible for the content of your Submissions and the consequences thereof. 1. Restrict or inhibit any other user from using and enjoying the Service's website, interfere or attempt to interfere with the proper workings of the Service, or do anything, which in the sole discretion of RemoteDerm, imposes an unreasonable or disproportionately large load on the Service infrastructure; 2. Post or transmit any unlawful, abusive, defamatory, or obscene information of any kind, including, without limitation, any submission constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, provincial, territorial, national, or international law or regulation; 3. Post or transmit any Submission, including, without limitation, articles, images, stories, software, or other material, which violates or infringes upon the rights of others, including material which is an invasion of privacy or publicity rights, or which is protected by any intellectual property rights, including, without limitation, copyright, patent, trade secret, trademark, or other proprietary rights herein, or derivative works with respect thereto, without first obtaining permission from the Owner or rights holder; 4. Post or transmit any Submission which contains a virus or other harmful component; 5. Post or transmit “junk mail,” “chain letters,” unsolicited mass mailing, or “spam”; and Use or “mine” the Service for commercial purposes, including, without limitation, posting, uploading, or transmitting any Submission which contains advertising, which engages in commercial activities, solicitations or sales, or which involves contests, sweepstakes, advertising, and pyramid schemes.`
    ]
  },
  /*{
    title: "Rules of Conduct Regarding Submissions",
    p: [
      `When using any of the features of the Service which allows you to post, upload, or make Submissions, it is a condition of your use of the Service that you do not:`
    ]
  },*/
  {
    title: "Grant of License Regarding Submissions",
    p: [
      `By posting or uploading Submissions to the Service, you grant RemoteDerm a royalty-free, perpetual, non- exclusive, irrevocable, unrestricted, worldwide license to (i) use, reproduce, store, adapt, translate, modify, make derivative works from, transmit, distribute, publicly perform, or display such Submissions for any purpose; and (ii) to sublicense to third parties the unrestricted right to exercise any of the foregoing rights. In addition to the grant of the above license, you hereby (i) waive all moral rights in your Submission in favor of RemoteDerm; (ii) consent to your name, address, and e-mail appearing as the contributor of your submission, where applicable, and to the disclosure and display of such information and any other information which appears in or is associated with your Submission; (iii) acknowledge and agree that the Owner is not responsible for any loss, damage, or corruption that may occur to your Submission; and (iv) acknowledge and agree that your Submission will be non-confidential.`
    ]
  },
  {
    title: "Registration",
    p: [
      `To use certain features of the Service, you may be asked to create an account with the Service. When you register with the Service, you agree (i) to provide true, accurate, current, and complete information about yourself as prompted by any registration form; and (ii) to maintain and promptly update the information to ensure it remains true, accurate, current, and complete.`,
      `If RemoteDerm has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, RemoteDerm has the right to suspend or terminate your access to all or part of the Service.`,
      `RemoteDerm’s use of any personally identifying information you provide as part of the registration process is governed by the terms of our <a href="/pages/policy">Privacy Policy</a> and <a href="/pages/cookies">Cookie Policy</a>.`
    ]
  },
  {
    title: "Financial Transactions",
    p: [
      `RemoteDerm is using a third party site called “Stripe” to conduct its financial transactions. You agree to comply with the <a href="https://stripe.com/legal/consumer">consumer terms use</a> of Stripe online payment platform.`,
      `RemoteDerm does not keep any nominal banking data of the user on its browser or infrastructure.`
    ]
  },
  {
    title: "Refunds",
    p: [
      `RemoteDerm is a professional service provider. Most of what you pay for the service goes to the physician that takes care of your case. For this reason, we do not issue refunds after your case was assessed by a physician. We issue refund for only the following scenarios:`,
      `1. You open the case, but you realize you no longer want our service prior to your case being assigned and assessed by a physician. In this scenario, you must leave a note on your case that says you no longer wish to receive the service prior to the the doctor's first response and notify us via support@remotederm.ca, and we issue you the full refund and close your case.`,
      `2. If you purchased 24 hour service, but for whatever reason such as emergencies or long backlog of cases, the doctor is not able to get back to you for the first time within the 24 hours, but the doctor still replies to you within the 72 hour period, we issue you a refund for the difference between the 24 hour package and the 72 hour package.`,
      `3. If you mean to request for a followup, please click the followup button in your case or on the right side of case list for the case you want to followup with. In case you made a mistake and you created a new case for an existing concern and paid for the full consultation again, we would refund you the difference between full consultation and the followup fee. We will close the new case and re-open your previous case for followup. If you made this mistake, please contact our support via email: <a href="mailto:support@remotederm.ca">support@remotederm.ca</a>.`
    ]
  },
  {
    title: "Passwords",
    p: [
      `You are responsible for maintaining the confidentiality of the Password you use in association with your account and are responsible for all activities that occur under your User Name and Password.`,
      `You agree to notify RemoteDerm immediately of any unauthorized use of your Password or account or any other breach of security, and to ensure that you exit from your account at the end of each session.`,
      `RemoteDerm will not be liable for any loss or damage arising from RemoteDerm’s failure or your failure to protect your Password or account information.`
    ]
  },
  {
    title: "Mobile SMS",
    p: [
      `You agree to receive recurring SMS/text messages from and on behalf of RemoteDerm LLC. through your wireless provider to the mobile number provided, even if your mobile number is registered on any state or federal Do Not Call list. Text messages may be sent using an automatic telephone dialing system or other technology. Service-related messages may include updates regarding your case, registration and account related notifications, promotions, specialist, and other marketing offers.`,
      `We use a service called Klaviyo for sending SMS and Emails. By using this service, you consent that you have read and agreed to Klaviyo's <a href="https://www.klaviyo.com/legal/privacy/privacy-notice">privacy policy.</a>`,
      `You understand that you do not have to opt into mobile SMS for creating a case and using our service, and your consent is not a condition of any service purchase with RemoteDerm. You opting into receiving SMS is completely voluntary.`,
      `We do not charge for Mobile SMS, but you are responsible for all charges and fees associated with text messaging imposed by your wireless provider. Message frequency varies. Message and data rates may apply. Check your mobile plan and contact your wireless provider for details. You are solely responsible for all charges related to SMS/text messages, including charges from your wireless provider.`,
      `You may opt-out of the Service at any time. Text the single keyword command STOP to +18443250641 or click the unsubscribe link (where available) in any text message to cancel. You'll receive a one-time opt-out confirmation text message. No further messages will be sent to your mobile device, unless initiated by you.`,
      `We may change any short code or telephone number we use to operate the Service at any time and will notify you of these changes. You acknowledge that any messages, including any STOP or HELP requests, you send to a short code or telephone number we have changed may not be received and we will not be responsible for honoring requests made in such messages.`,
      `The wireless carriers supported by the Service are not liable for delayed or undelivered messages. You agree to provide us with a valid mobile number. If you get a new mobile number, you will need to sign up for the program with your new number.`,
      `Your agree and understand that communication via SMS does not meet the provisions of Personal Information Protection and Electronic Document Act (PIPEDA). Hence you should never send any SMS regarding any medical related questions.`,
      `To the extent permitted by applicable law, you agree that we will not be liable for failed, delayed, or misdirected delivery of any information sent through the Mobile SMS, any errors in such information, and/or any action you may or may not take in reliance on the information or Service.`,
    ],
  },
  {
    title: "Chatbot",
    p: [
      `The chatbot on our website is powered by a third-party provider called Tidio. RemoteDerm uses this chatbot to provide you with answers to frequently asked non-medical questions about RemoteDerm's services. The chatbot does not meet the provisions of Personal Information Protection and Electronic Document Act (PIPEDA). Hence it should not be used to ask any medical related questions.`,
      `To securely seek medical advise for your dermatology needs through RemoteDerm, please <a href="/pages/auth?type=register">sign up</a> or <a href="/pages/auth?type=login">log in</a> if you already have an account. You can alternatively use our <a href="/pages/contact-us">contact-us</a> page or email us to <a href="mailto:support@remotederm.ca">support@remotederm.ca</a> with any questions you may have before signing up.`,
      `By using the chatbot, you acknowledge that you fully understand the risks, limitations, conditions, and instructions for use. You understand and accept the risks associated with the use of the chatbot service and you agree with <a href="https://www.tidio.com/privacy-policy/">Tidio's Privacy Policy</a>. You acknowledge and agree that despite recommendations that encryption software be used as a security mechanism for electronic communications, it is possible that communications using this chatbot may not be encrypted. Despite this, you agree to communicate with this chatbot with a full understanding of the risk involved. You acknowledge that you may, at any time, withdraw the option of communicating electronically through this service by closing the chat window.`
    ],
  },
  {
    title: "Security",
    p: [
      `Any information sent or received over the Internet is generally not secure. RemoteDerm cannot guarantee the security or confidentiality of any communication to or from the Service.`
    ]
  },
  {
    title: "Modification to the Service",
    p: [
      `RemoteDerm reserves the right any time, and from time to time, to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice to you.`,
      `RemoteDerm shall have no liability to you or any third party for any modifications, suspension, or discontinuance of the Service or any part thereof.`
    ]
  },
  {
    title: "Use Prohibited Where Contrary to Law",
    p: [
      `Use of this Service is unauthorized in any jurisdiction where the Service or any of the Content may violate any laws or regulations. You agree not to access or use the Service in such jurisdictions.`,
      `You agree that you are responsible for compliance with all applicable laws or regulations. Any contravention of this provision (or any provision of this Agreement) is entirely at your own risk.`
    ]
  },
  {
    title: "Governing Law and Jurisdiction",
    p: [
      `The Service Owner is licensed by Colleges of Physicians and Surgeons of Alberta. You agree that all matters relating to your access or use of the Service and its Content shall be governed by the laws of the Alberta province as well as the laws of Canada applicable therein, without regard to conflict of laws principles.`,
      `You and RemoteDerm (Owner) mutually agree to forfeit any rights to litigate claims in a court or before a jury or to participate in a class action or representative action with respect to a claim. Any dispute between RemoteDerm (Owners, physicians, its agents) and you arising from or relating to these Terms of Use and their interpretation or the breach, termination or validity thereof, the relationships which result from these Terms of Use, including disputes about the validity, scope or enforceability of this arbitration provision will be settled by binding arbitration.`,
      `By using this platform you agree to submit and resolve any and all disputes via arbitration in Alberta, Canada. You have to provide at least 2 months advance written notice of your intent to file for arbitration by emailing <a href="mailto:support@remotederm.ca">support@RemoteDerm.ca</a> with title Arbitration. The arbitration process needs to start within 6 months of cause of action arose or be forever waived and barred, unless such waiver is prohibited by law.`
    ]
  },
  {
    title: "Waiver",
    p: [
      `Any consent by RemoteDerm to, or waiver of, a breach of this Agreement which you have committed, whether expressed or implied, shall not constitute a consent to, or waiver of any other, different or subsequent breach.`
    ]
  },
  {
    title: "Severability",
    p: [
      `The invalidity or un-enforceability of any provision of this Agreement or any covenant contained herein shall not affect the validity or enforceability of any other provision or covenant contained herein and any such invalid provision or covenant shall be deemed severable from the rest of this Agreement.`
    ]
  },
  {
    title: "Notice",
    p: [
      `Questions or comments regarding the Service should be directed by email to <a href="mailto:support@remotederm.ca">support@RemoteDerm.ca</a>.`
    ]
  },
  {
    title: "Disruption in Service",
    p: [
      `In no event shall RemoteDerm be held accountable for any default or delay in providing the Service due to war, act of hostility, natural disaster, failure of an electrical system, Internet network, or computer sabotage, or any other event beyond its control.`,
      `RemoteDerm is committed to make all possible and reasonable efforts in order to resolve the effects of any such incidents.`
    ]
  },
  {
    title: "Termination",
    p: [
      `RemoteDerm may, in its sole discretion, cancel or terminate your right to use the Service, or any part of the Service, at any time without notice. In the event of termination, you are no longer authorized to access the Service, or the part of the Service affected by such cancellation or termination.`,
      `The restrictions imposed on you with respect to material downloaded from the Service and the disclaimers and limitations of liabilities set forth in this Agreement, shall survive termination of this Agreement. RemoteDerm shall not be liable to any party for such termination.`
    ]
  },
  {
    title: "Entire Agreement",
    p: [
      `This is the entire Agreement between you and RemoteDerm relating to your access and use of the Service.`
    ],
  },
];

export const policy = [
  {
    title: "ACCEPTANCE AND APPLICATION",
    p: [
      `RemoteDerm ('RemoteDerm', 'we' or 'us'), provides this Privacy Policy ('Privacy Policy') to inform you ('User' or 'Users' or 'you') of our policies and procedures relating to our website (the “Site”, “Platform”), and all related websites, mobile applications, and other services (“Services”, “TeleDermatology Sevices” & “TeleMedicine Services”) provided by us and all other communications with you.`,
      `This Privacy Policy applies to anyone (including but not limited to Patient and Medical Professional) using the Services. By visiting the Site or by using the Platform/Service, you accept the terms and conditions of this Policy. If you don’t think this policy applies to you or you don’t agree with it, don’t use this Platform/Service.`,
      `This Privacy Policy (“Policy”) explains the information that we collect through the Platform/Service, how we use and disclose such information, and the ways we protect such information. We reserve the right at any time, in our sole discretion and without any liability, to update or modify the Privacy Policy without notice`,
    ],
  },
  {
    title: "Collection of Information",
    p: [
      `We collect information that you provide to us directly, such as when you create or modify your account or when you contact us, when contact customer service. This information may include (but is not limited to): your name, postal address, email address, phone number, gender, date of birth, medical history, photos and any other information you choose to provide. The legal bases for our processing of Personal Information is for providing the Teledermatology Service to you. We may also process Personal Information upon your consent, asking for it as appropriate.`,
      `Personal Information also includes other information, such as preferences, when any such information is linked to information that identifies a specific individual. If you choose to withhold any Personal Information requested by us, it may not be possible for you to gain access to certain parts of the TeleDermatology Service.`,
      `We may obtain information, including Personal Information, from third parties and sources other than the Service, such as our partners or advertisers. If we combine or associate information from other sources with Personal Information that we collect through the Service, we will treat the combined information as Personal Information in accordance with this Policy.`,
      `When you use the Service, we may automatically record certain information from your device by using various types of technology, including (but not limited to) IP address or other device address or ID, web browser and/or device type, the web pages or sites visited just before or just after using the Service, the pages or other content you view or interact with on the Service, and the dates and times of the visit, access, your location data (which is anonymous) or use of the Service.`,
      `We use non-personal information to review our traffic, to individualize our Service, such as remembering your information so that you will not have to re-enter it; to observe how Users use our services, to provide customized advertisements, content, and information, to monitor and analyze usage of the Services, to monitor and analyze statistics, to ensure proper technical administration, to track your entries, submissions, and status in any promotions or other activities on the Service to increase the functionality and usability of our Services and to better adapt them according to the needs of our Users. This type of information will not allow you to be personally identified. We also may use these techniques and technologies to collect information regarding your interaction with email messages (whether you open, click on, or forward a message). You may limit the automatic collection of certain information by our Service, for instance by disabling the cookies using your browser options. Disabling the cookies may limit or prevent you from using specific features on our Service, such as maintaining an online account.`,
    ],
  },
  {
    title: "Use of Information",
    p: [
      `We take steps designed to ensure that only those employees who need access to your Personal Information will have access to it. We use the collected information to function, maintain, enhance and provide all features of the Service (TeleDermatology Service and other Services including research, clinical trials, educational, teaching and development purposes), to provide the services and information that you asked for, to reply to comments and questions and to provide support to users of the Service.`,
      `We also use the information to better comprehend and analyze the usage trends and preferences of our users and to improve the Service, to communicate with you as well as to comply with legal and regulatory requirements, where applicable.`,
      `We would not use your personal information for other purposes without your consent. We may use your information to send you newsletter or other advertisement information with regards to our services or products that we offer on our platform.`,
    ],
  },
  {
    title: "Administer Prize Draws and Competitions.",
    p: [
      `We may use your information to administer prize draws and competitions when you elect to participate in our competitions.`,
    ],
  },
  {
    title: "To send you Marketing and Promotional Communications.",
    p: [
      `We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our , subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the 'WHAT ARE')`,
    ],
  },
  {
    title: "Deliver Targeted Advertising to You:",
    p: [
      `We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.`,
    ],
  },
  {
    title: "Opting out of Email Marketing:",
    p: [
      `You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using “Contact Us”. You will then be removed from the marketing email list, however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.`,
    ],
  },
  {
    title: "Sharing Information",
    p: [
      `We may disclose certain information that we collect from you in order to provide the service among our company and affiliates, subsidiaries, with third party service providers who provide hosting and other services for RemoteDerm. We use the delivery address provided by you along with your full name, email, and telephone number to register you an account with our prescription delivery partner. Additionally we share the prescription with our delivery partner or fax it to your pharmacy based on your choice. We limit the information provided to these service providers to that which is reasonably necessary for them to perform their functions. All the service providers we use are required to have the same level of privacy protection as ours.`,
      `We may share non-personal information with third parties including but not limited to statistical, machine learning, advertising, or marketing purposes. We may also share information with other physicians and health care allied who provide shared care for our users/patients/you. In compliance with governmental regulations, we may share information that may be required by law.`,
      `We may also share information to an acquirer, successor or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, or any other situation in which Personal Information may be disclosed or transferred as one of our corporate assets. However, under no circumstances will these transactions permit the use of your information for purposes other than those for which you have given us your consent and only if the recipient of your Personal Information commits to a privacy policy that has terms substantially consistent with this Policy.`,
    ],
  },
  {
    title: "New Items",
    p: [
      `In order to improve our service to the users as well as for marketing purposes, we may provide certain information that get automatically collected, some non-personally identifiable information to various third parties for different reasons including compliance with different reporting obligations; (ii) marketing or business purposes; or (iii) to better sense of your usage, habits and interests pattern for certain programs, content, services, and/or functionality available through the Service.`,
    ],
  },
  {
    title: "Security and Retention",
    p: [
      `We follow generally accepted industry standards to protect the information submitted to us, both during transmission as well as when we receive it. We maintain appropriate physical, technical and administrative safeguards to protect Personal Information against accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful form of processing of the Personal Information in our possession. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. We cannot guarantee, ensure or warrant the security of any information you transmit to us or store on the Service, and you do so at your own risk. We also cannot guarantee that such information may not be disclosed, altered, accessed or destroyed by breach of any of our physical, technical, or administrative safeguards. If you believe your Personal Information has been compromised, please contact us as set forth in the “Contact Us” section. If we learn of a security systems breach, we will inform you and the authorities of the occurrence of the breach in accordance with applicable law.`,
      `We will only keep your Personal Information for as long as reasonably necessary to fulfill the relevant purposes set out in this Policy and in order to comply with our legal and regulatory obligations. If you would like further information regarding the periods for which your Personal Information will be kept, please contact us as set forth in the “Contact Us” section.`,
    ],
  },
  {
    title: "Third-Party Services",
    p: [
      `The Service may contain features or links to websites and services provided by third parties. Any information you provide on third-party sites or services is provided directly to the operators of such services and is subject to those operators’ policies, if any, governing privacy and security, even if accessed through the Service. We are not responsible for the content or privacy and security practices and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to learn about third parties’ privacy and security policies before providing them with information.`,
      `Social Media :
            We offer you the ability to register and login using your third-party social media account details (like your Facebook or Twitter or Instagram or other social media logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often (not limited to) include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform. We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant . Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.`,
      `Analytical Information :
            We may use various data analytical websites for our marketing purposes. They utilize the Data collected to track and examine the use of this Platform, to prepare reports on its activities and share them with other services. Google analytical and others may use the Data collected to contextualize and personalize the ads of its own advertising network.`,
      `We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes. Like many businesses, we may collect information through cookies and similar technologies.`,
      `The information we collect includes:`,
      `1. Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).`,
      `2. Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access them. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system and system configuration information.`,
      `3. Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Service. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.`,
    ],
  },
  {
    title: "Personal Information",
    p: [
      `On written request and subject to proof of identity, you may access the Personal Information that we hold, used or communicated and ask that any necessary corrections be made, where applicable, as authorized or required by law. However, to make sure that the Personal Information we maintain about you is accurate and up to date, please inform us immediately of any change in your Personal Information by email.`,
    ],
  },
  {
    title: "Children’s Privacy",
    p: [
      `We do not knowingly solicit data from or market to children under 18 years of age. By using the , you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the . If we learn that personal information from users less than 18 years of age has been collected directly, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at privacy@remotederm.ca.`,
    ],
  },
  {
    title: "Surveys",
    p: [
      `You may receive a request to fill surveys when you visit the Platform. We use information from surveys to better understand the needs of our clients and to gather information about health care trends and issues. We may share anonymized information from surveys with third parties who perform data management services for our site. Those third parties have agreed to keep all data from surveys confidential. Also, we may share information from surveys in an aggregated, anonymized form with third parties with whom we have a business relationship.`,
    ],
  },
  {
    title: "Update",
    p: [
      `Please revisit Privacy & Policy page (this page) occasionally to stay aware of any changes to this Policy, which we may update from time to time. If we modify the Policy, we will make it available through the Service, and indicate the date of the latest revision, and will comply with applicable law. Your continued use of the Service after the revised Policy has become effective indicates that you have read, understood and agreed to the current version of the Policy.`,
    ],
  },
  {
    title: "Contact Us",
    p: [
      `If you have any questions or comments about this Policy or your Personal Information, to make an access or correction request, to exercise any applicable rights, to make a complaint, or to obtain information about our policies and practices, our Privacy Officer (or Data Protection Officer) can be reached by email using the privacy@RemoteDerm.ca with title Privacy Policy`,
    ],
  },
];

export const consent = [
  {
    title: "Risks of Using Electronic Communication",
    p: `The RemoteDerm and its affiliated physicians (referred here all as “RemoteDerm”) will use reasonable means to protect the security and confidentiality of information sent and received using the Platform (“Platform” is defined as the RemoteDerm website and its all means of online communications including Website/portal and Email). The terms “Services” and “Platforms” are used interchangeably here. However, because of the risks outlined below, the RemoteDerm cannot guarantee the security and confidentiality of electronic communications:`,
    options: [
      `1. Use of electronic communications to discuss sensitive information can increase the risk of such information being disclosed to third parties.`,
      `2. Despite reasonable efforts to protect the privacy and security of electronic communication, it is not possible to completely secure the information.`,
      `3. Employers and online services may have a legal right to inspect and keep electronic communications that pass through their system.`,
      `4. Electronic communications can introduce malware into a computer system, and potentially damage or disrupt the computer, networks, and security settings.`,
      `5. Electronic communications can be forwarded, intercepted, circulated, stored, or even changed without the knowledge or permission of the Physician or the patient.`,
      `6. Even after the sender and recipient have deleted copies of electronic communications, back-up copies may exist on a computer system.`,
      `7. Videoconferencing using services such as Zoom, Skype or FaceTime may be more open to interception than other forms of videoconferencing`,
    ],
  },
  {
    title:
      "If the email or text is used as an e-communication tool, the following are additional risks:",
    p: ``,
    options: [
      `1. Email, text messages, and instant messages can more easily be misdirected, resulting in increased risk of being received by unintended and unknown recipients.`,
      `2. Email, text messages, and instant messages can be easier to falsify than handwritten or signed hard copies. It is not feasible to verify the true identity of the sender, or to ensure that only the recipient can read the message once it has been sent`,
    ],
  },
  {
    title: "Conditions of Using the Services",
    p: ``,
    options: [
      `1. While the Physician will attempt to review and respond in a timely fashion to your electronic communication, the Physician cannot guarantee that all electronic communications will be reviewed and responded to within any specific period of time. The Services will not be used for medical emergencies or other time-sensitive matters.`,
      `2. If your electronic communication requires or invites a response from the Physician and you have not received a response within a reasonable time period, it is your responsibility to follow up to determine whether the intended recipient received the electronic communication and when the recipient will respond`,
      `3. Electronic communication is not an appropriate substitute for in-person or over-the-telephone communication or clinical examinations, where appropriate, or for attending the Emergency Department when needed. You are responsible for following up on the Physician’s electronic communication and for scheduling follow up where warranted.`,
      `4. Electronic communications concerning diagnosis or treatment may be printed or transcribed in full and made part of your medical record. Other individuals authorized to access the medical record, such as staff and billing personnel, may have access to those communications.`,
      `5. The Physician may forward electronic communications to staff and those involved in the delivery and administration of your care. The Physician might use one or more of the Services to communicate with those involved in your care. The Physician will not forward electronic communications to third parties, including family members, without your prior written consent, except as authorized or required by law.`,
      `6. You agree to inform RemoteDerm of any types of information you do not want sent via the Services, in addition to those set out above. You can add to or modify the above list at any time by notifying RemoteDerm in writing.`,
      `7. Some Services might not be used for therapeutic purposes or to communicate clinical information. Where applicable, the use of these Services will be limited to education, research, information, and administrative purposes.`,
      `8. RemoteDerm cannot provide virtual care for assessment of mole or any other pigmented lesion, skin cancer ( melanoma, basal cell carcinoma, squamous cell carcinoma, Merkel cell carcinoma, Mycosis fungoides or any other cancer types), and liquid nitrogen treatment. These conditions require an in person visit. A disclaimer is also shown to you during your case submission. If you choose to submit your case regarding these issues, the dermatologist reserves the right to cancel and refund your case, or refer you to an in person physician, and we will not be responsible for diagnosis of these conditions.`,
      `9. RemoteDerm is not responsible for information loss due to technical failures associated with your software or internet service provider.`,
      `10. RemoteDerm is not responsible for delays in processing prescriptions by pharmacies or its delivery partners.`,
    ],
  },
  {
    title: "Instructions for Communication Using the Services",
    p: `To communicate using the Services, you must:`,
    options: [
      `1. Reasonably limit or avoid using an employer’s or other third party’s computer.`,
      `2. Inform the Physician of any changes in the your (patient’s) email address, mobile phone number, or other account information necessary to communicate via the Services.`,
      `3. Include in the message’s subject line an appropriate description of the nature of the communication (e.g. medication renewal”), and your full name in the body of the message.`,
      `4. Review all electronic communications to ensure they are clear and that all relevant information is provided before sending to RemoteDerm.`,
      `5. Ensure RemoteDerm is aware when you receive an electronic communication from RemoteDerm, such as by a reply message or allowing “read receipts” to be sent.`,
      `6. Take precautions to preserve the confidentiality of electronic communications, such as using screen savers and safeguarding computer passwords.`,
      `7. Withdraw consent only by email, using Platform or written communication to the RemoteDerm.`,
      `8. If you require immediate assistance, or if your condition appears serious or rapidly worsens, you should not rely on the Services or Platform. Rather, you should go to the nearest Emergency Department or urgent care clinic.`,
    ],
  },
  {
    title: "Acknowledge",
    p: `You acknowledge that you have read and fully understand the risks, limitations, conditions of use, and instructions for use of the selected electronic communication Services more fully described. You understand and accept the risks outlined, associated with the use of the Services in communications with RemoteDerm and RemoteDerm’s staff. You consent to the conditions and will follow the instructions outlined above, as well as any other conditions that the RemoteDerm may impose on communications with patients using the Services and/or Platform. You acknowledge and understand that despite recommendations that encryption software be used as a security mechanism for electronic communications, it is possible that communications with the RemoteDerm or the RemoteDerm’s staff using the Services may not be encrypted. Despite this, You agree to communicate with the RemoteDerm or the RemoteDerm’s staff using these Services/Platform with a full understanding of the risk. You acknowledge that either You or the RemoteDerm may, at any time, withdraw the option of communicating electronically through the Services/Platform upon providing written notice. Any questions You had have been answered.`,
  },
];

export const cookies = [
  {
    title: "Cookie Policy",
    p: [
      `This Cookie Policy explains how RemoteDerm LLC. (“Company”, “we”, “us”, and “our”) uses cookies and similar technologies to recognize you when you visit our websites at https://remotederm.ca and https://patient.remotederm.ca (“Websites”). It explains what these technologies are and why we use them, as well as your rights to control our use of them.`,
      `In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.`,
    ],
  },
  {
    title: "What are cookies?",
    p: [
      `Cookies and other online tracking technologies are small bits of data or code that are used to identify your devices when you use and interact with our websites and other services. They are often used for remembering your preferences, to identify you when you log into a site, or to give us the ability to recognize you when you interact with us or our website. Cookies do not typically identify you as an individual, just the device that you are using. For further information on cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit <a href="http://allaboutcookies.org">allaboutcookies.org</a>.`,
      `We can only place cookies for lawful reasons, and we do. For now, the only reasons we place them are:`,
      `   2.1. To help our site work properly;`,
      `   2.2. To identify you when you visit our site;`,
      `   2.3. To make your interaction with the site easier (for instance, allowing you to remain logged in and not have to enter your user ID and password every time you come to remotederm.ca); or`,
      `   2.4. To track activity on our sites.`,
      `If you use our websites, that means that you agree to our cookie policy. For more information, see the section on Managing Cookies.`,
      `Using information collected from cookies: where we want to use the information that cookies and similar technologies collect, we either need your consent or a legitimate interest.`,
    ],
  },
  {
    title: "What cookies do we use and why?",
    options: [
      `3.1. Essential Cookies and Similar Technologies: These are vital for the running of our services on our websites and apps. Without the use of these cookies, parts of our websites would not work. For example, we use cookies to help us identify which of our customers have a customer account ID with a password. We use these cookies to tell if you're logged in to your account, which allows extra functionality, such as creating a new patient case.`,
      `3.2. Analytics Cookies and Similar Technologies: These collect information about your use of our websites and apps and enable us to improve the way they work. For example, analytics cookies show us which are the most frequently visited pages on our websites. They help us record how you interact with our websites, such as how you navigate around pages and from page to page, identifying improvements we can make to the customer journey. They also help identify any difficulties you have accessing our services, so we can fix any problems. Additionally, these cookies allow us to see overall patterns of usage at an aggregated level.`,
      `3.3. Functional/Preference Cookies and Similar Technologies: These cookies collect information about your choices and preferences, and allow us to remember things like language, your username (so you can log in faster), text size, and location, so we can show you relevant content to where you are. They allow us to customize the services you have accessed. We also may use these cookies to provide you with services such as video clips.`,
      `3.4. Tracking/Advertising Cookies and Similar Technologies: These cookies record your visit to our websites, the pages you have visited, and the links you have clicked. They gather information about your browsing habits and remember that you have visited a website. We (and third-party advertising platforms or networks) may use this information to make our websites, content, and advertisements displayed on them more relevant to your interests (this is sometimes called "behavioral" or "targeted" advertising). These types of cookies are also used to limit the number of times you see an advertisement as well as to help measure the effectiveness of advertising campaigns.`,
      `3.5. Web Beacons and Tracking Pixels: These are bits of data that count the number of users who access a website or webpage and can also allow us to see if a cookie has been activated. Web beacons used on web pages or in emails allow us to see how successful an article has been, or whether an email message was successfully delivered and read in a marketing campaign. Web beacons are also used to verify any clicks through to links or advertisements contained in emails. We may use this information to help us identify which emails are more interesting to you and to inform advertisers how many customers have clicked on their adverts (the information shared with advertisers is aggregated and does not identify you individually).`,
      `3.6. Flash Cookies: We may, in certain situations, use Adobe Flash Player to deliver special content, such as video clips or animation. To improve your user experience, Local Shared Objects (commonly known as Flash cookies) are used to provide functions such as remembering your settings and preferences. Flash cookies are stored on your device, but they are managed through an interface different from the one provided by your web browser.`,
      `3.7. Tracking URLs: Tracking URLs are a special web link that allows us to measure when a link is clicked on. They are used to help us measure the effectiveness of campaigns and advertising and the popularity of articles that are read.`,
      `3.8. Device Fingerprinting: We may collect and analyze a device's browser information to help identify that device, present content correctly, conduct analytics and help prevent and detect fraud.`,
    ],
  },
  {
    title: "Cookies Chart",
    p: [
      `Some of the cookies we commonly use are listed in the following cookie chart.  This list is not exhaustive, but it is intended to illustrate the primary reasons for certain types of cookies set by us and third parties on our websites.`,
    ],
    table: [
      {
        title: "4.1. Essential/Functional Cookies",
        headers: [`NAME`, `DOMAIN`, `PURPOSE`, `PROVIDER`, `DURATION`],
        rows: [
          [
            `token-remote-patient`,
            `remotederm.ca`,
            `Stores unique token that is used to facilitate communication to our service’s backend API`,
            `RemoteDerm LLC.`,
            `30 days`,
          ],
          [
            `G_ENABLED_IDPS`,
            `remotederm.ca`,
            `Used for Google Single Sign-On`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `Session`,
          ],
          [
            `G_AUTHUSER_H`,
            `remotederm.ca`,
            `Used to store Google Auth session id`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `1 day`,
          ],
        ],
      },
      {
        title: "4.2. Analytics Cookies",
        headers: [`NAME`, `DOMAIN`, `PURPOSE`, `PROVIDER`, `DURATION`],
        rows: [
          [
            `MR`,
            `bat.bing.com`,
            `This cookie is used by Microsoft to reset or refresh MUID cookie`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `7 days`,
          ],
          [
            `_uetsid`,
            `remotederm.ca`,
            `Bing Ads sets this cookie to engage with a user that has previously visited the website`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `7 days`,
          ],
          [
            `_uetvid`,
            `remotederm.ca`,
            `Bing Ads sets this cookie to engage with a user that has previously visited the website`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `1 year 24 days`,
          ],
          [
            `_uetsid`,
            `remotederm.ca`,
            `Bing Ads sets this cookie to engage with a user that has previously visited the website`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `Session`,
          ],
          [
            `_uetvid`,
            `remotederm.ca`,
            `Bing Ads sets this cookie to engage with a user that has previously visited the website`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `Session`,
          ],
          [
            `_hjAbsoluteSessionInProgress`,
            `remotederm.ca`,
            `Hotjar sets this cookie to track the beginning of the user’s journey for a total session count. It does not contain any identifiable information.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `30 min`,
          ],
          [
            `_hjSessionUser_{siteid}`,
            `remotederm.ca`,
            `HotJar sets this cookie when a user first lands on a page. It persists the Hotjar User ID which is unique to the site. Hotjar does not track users across different sites. It ensures data from subsequent visits to the same site are attributed to the same user ID.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `1 year`,
          ],
          [
            `_hjFirstSeen`,
            `remotederm.ca`,
            `HotJar sets this to Identify a new user’s first session. It is used by Recording filters to identify new user sessions.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `30 min`,
          ],
          [
            `_hjIncludeInSessionSample_{siteid}`,
            `remotederm.ca`,
            `HotJar sets this to determine if a user is included in the data sampling defined by your site's daily session limit.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `2 min`,
          ],
          [
            `_hjSession_{siteid)`,
            `remotederm.ca`,
            `HotJar uses this to hold current session data. It ensures subsequent requests in the session window are attributed to the same session.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `30 min`,
          ],
          [
            `_hjIncludeInPageviewSample`,
            `remotederm.ca`,
            `Hotjar sets this cookie to check whether a user is included in the data sampling defined by the site’s pageview limit.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `2 min`,
          ],
          [
            `_hjAbsoluteSessionProgress`,
            `remotederm.ca`,
            `Hotjar sets this cookie to detect a user’s first pageview session, which is a True/False flag set by the cookie.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `30 min`,
          ],
          [
            `_hjRecordingLastActivity`,
            `remotederm.ca`,
            `Hotjar sets this cookie when a user recording starts and when data is sent through the websocket.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `Session`,
          ],
          [
            `_hjRecordingEnabled`,
            `remotederm.ca`,
            `Hotjar sets this cookie when a recording starts and is read when the recording module is initialized, to see if the user is already in a recording in a particular session.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `Session`,
          ],
          [
            `_hjViewportId`,
            `remotederm.ca`,
            `Hotjar sets this cookie to store user’s viewport details such as size and dimension.`,
            `<a href="https://www.hotjar.com/legal/policies/privacy/">HotJar Inc.</a>`,
            `Session`,
          ],
          [
            `__kla_id`,
            `remotederm.ca`,
            `Klaviyo sets this cookie to collect information on the visitor’s behavior. This information is used for internal analytics and to optimize the website. It also registers if the visitor has subscribed to the newsletter.`,
            `<a href="https://www.klaviyo.com/legal/privacy">Klaviyo Inc.</a>`,
            `1 year 1 month 4 days`,
          ],
        ],
      },
      {
        title: "4.3. Advertising Cookies",
        headers: [`NAME`, `DOMAIN`, `PURPOSE`, `PROVIDER`, `DURATION`],
        rows: [
          [
            `MUID`,
            `bing.com`,
            `Bing sets this cookie to recognize unique web browsers visiting Microsoft sites. This cookie is used for advertising, site analytics, and other operations.`,
            `<a href="https://privacy.microsoft.com/en-US/">Microsoft Inc.</a>`,
            `1 year 24 days`,
          ],
          [
            `_gcl_au`,
            `remotederm.ca`,
            `Google Tag Manager sets the cookie to experiment advertisement efficiency of websites using Google Ads Services`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `3 months`,
          ],
          [
            `_ga`,
            `remotederm.ca`,
            `Google Analytics sets this cookie to calculate visitor, session and campaign data and track site usage for the site’s analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `1 year 1 month 4 days`,
          ],
          [
            `_gid`,
            `remotederm.ca`,
            `Google Analytics sets this cookie to store information on how visitors use a website white also creating an analytics report of the website’s performance. Some of the collected data includes the number of visitors, their source, and the pages they visit anonymously.`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `1 day`,
          ],
          [
            `_gat_UA-*`,
            `remotederm.ca`,
            `Google Analytics sets this cookie for user behavioral tracking.`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `1 minute`,
          ],
          [
            `_ga_*`,
            `remotederm.ca`,
            `Google Analytics sets this cookie to store and count page views.`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `1 year 1 month 4 days`,
          ],
          [
            `FPAU`,
            `remotederm.ca`,
            `Google Tag Manager sets this cookie for analytics and reporting purposes to gather data about the user and their activity on the website.`,
            `<a href="https://policies.google.com/privacy?hl=en-US">Google Inc.</a>`,
            `3 months`,
          ],
          [
            `_fbp`,
            `remotederm.ca`,
            `Meta sets this cookie to display advertisements when either on Facebook or on a digital platform powered by Facebook advertising after visiting the website.`,
            `<a href="https://www.facebook.com/privacy/policy/">Meta Inc.</a>`,
            `3 months`,
          ],
        ],
      },
    ],
  },
  {
    title: "How do we use cookies and similar technologies?",
    paragraph: [
      {
        title: "5.1. Protect our Networks",
        p: [
          `Cookies and similar technologies help us identify and prevent threats to our sites. They are necessary to protect your information and our business from outside threats.`,
        ],
      },
      {
        title: "5.2. Allow you to Access our Services",
        p: [
          `Cookies and similar technologies permit your connection to our websites: our servers receive and record information about your computer, device, and browser, including potentially your IP address, browser type, other software or hardware information, and your geographic location.`,
        ],
      },
      {
        title: "5.3. Access Usage of Services",
        p: [
          `We use information about your usage of our services, websites and apps, such as pages you have visited, content you have viewed, search queries you have run, and advertisements you have seen or interacted with to assess how our services are used.`,
        ],
      },
      {
        title: "5.4. Provide Secure Tele-Medicine Functionality",
        p: [
          `Cookies and similar technologies help us provide functionality that you need to navigate our sites, open new cases, and manage your correspondence with a physician.`,
        ],
      },
      {
        title: "5.5. Provide Relevant Content",
        p: [
          `We adjust the content on our websites and in our communications with you depending on what we know about the content, products and services that you like. This means we can highlight content that we believe will be of interest to you. We provide personalization by using cookies, IP addresses, web beacons, URL tracking and mobile app settings.`,
        ],
      },
    ],
  },
  {
    title: "Managing Cookies",
    paragraph: [
      {
        title: "6.1. Our Partners",
        p: [
          `When you visit our websites, our partners and we will set cookies and similar technologies on your browser in order to help us make the sites work, analyze site usage, deliver ads, and personalize your experience.`,
          `When you visit our sites you'll see notices about the use of cookies by us and our partners. You'll be able to learn more about the specific cookies in use and how to control them. By using our sites, you understand that cookies and similar technologies are used and agree that by continuing to use the site, you consent to their placement.`,
          `In the future we plan to release improved controls to allow you to choose which partners can collect and use your information.`,
          `Most modern browsers are set to accept cookies by default, but you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please consult the "Help" section of your browser for more information. The following is information about how to manage cookies on the most popular browsers:`,
        ],
        list: [
          `<a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies">Chrome</a>`,
          `<a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</a>`,
          `<a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Firefox</a>`,
          `<a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac">Safari</a>`,
          `<a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">Edge</a>`,
          `<a href="https://help.opera.com/en/latest/web-preferences/">Opera</a>`,
        ],
      },
      {
        title: "6.2. Controlling OBA Cookies",
        p: [
          `In addition to being able to control cookies by changing the settings in your browser, on your computer, or in your mobile device's settings, you can also control which companies can set cookies and similar technologies on your devices, by visiting the following links (you'll need to turn any cookie or ad blockers off for the control pages to show which companies you can opt-out of):`,
        ],
        list: [
          `Internet Advertising Bureau (IAB) Your Online Choices website provides more information about controlling cookies. It also provides an easy way to opt-out of behavioral advertising from each (or all) of the networks represented by the <a href="https://www.youronlinechoices.com/">European Interactive Digital Advertising Alliance</a>.`,
          `<a href="https://thenai.org/opt-out/">The Network Advertising Initiative<a/> (NAI) control page allows you to control behavioral advertising from each of the ad networks represented by the NAI.`,
          `<a href="https://optout.aboutads.info/?c=2&lang=EN">The Digital Advertising Alliance's</a> self-regulatory program for online behavioral advertising control page allows you to control behavioral advertising from the ad networks it represents. Also visit <a href="https://youradchoices.ca/">The Digital Advertising Alliance of Canada</a>.`,
          `Importantly, these ad networks set cookies to remember that you have chosen to opt-out. If you clear your cache the opt-outs will not be applied and you would need to opt-out again.`,
        ],
      },
      {
        title: "6.3. Controlling Flash Cookies",
        p: [
          `If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to block Flash Cookies storage using the tools contained in the <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Website Storage Settings Panel</a>. You can also control Flash Cookies by going to the <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html">Global Storage Settings Panel</a> and following the instructions (which may include instructions that explain, for example, how to delete existing Flash Cookies (referred to “information” on the Macromedia site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are not being delivered by the operator of the page you're on at the time).`,
          `Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our services or online content.`,
        ],
      },
    ],
  },
  {
    title: "Changes to this Policy",
    p: [
      `From time to time we make changes to this Policy. This may be because of changes in the law, best practices, changes in our services or treatment of your personal information. Where necessary, we will notify you of these changes. We will always display clearly when the privacy policy was last amended on the website.`,
    ],
  },
  {
    title: "Contact Us",
    p: [
      `In addition to any general data protection enquiries you may have, you can use these details to contact our privacy team at privacy@remotederm.ca.`,
    ],
  },
];

import React, { useEffect, useState, useRef } from "react";
import { IProps } from "./types";
import Mobile from "./mobile";
import Desktop from "./desktop";
import Params from "@utilities/param";
import Request, { URLS } from "@api";
import { useCookies } from "react-cookie";
import Link from "next/link";
// import Christmas from "./christmas";
// import BlackFriday from "./blackFriday";
// import Valentine from "./valentine";
// import Women from "./women";
// import Easter from "./easter";
// import Mothers from "./mothers";
// import Summer from "./summer";

const options = {
  root: null,
  threshold: 0.15,
  rootMargin: `70px`,
};

const Header = (props: IProps) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sticky, setSticky] = useState(false);
  const containerRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const user = localStorage.getItem(Params.LOCAL_STORAGE.PATIENT_INFO);
    if (user) {
      setUser(JSON.parse(user as any));
    }
    if (
      cookies[Params.COOKIES.TOKEN] &&
      cookies[Params.COOKIES.TOKEN_REMOTE_PATIENT]
    ) {
      getUserInfo(cookies[Params.COOKIES.TOKEN]);
    } else {
      localStorage.removeItem(Params.LOCAL_STORAGE.PATIENT_INFO);
      removeCookie(Params.COOKIES.TOKEN);
      removeCookie(Params.COOKIES.TOKEN_REMOTE_PATIENT, {
        path: "/",
        domain: ".remotederm.ca",
        expires: new Date(new Date().setDate(new Date().getDate() - 1)),
      });
      setUser(null);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    setSticky(!entry?.isIntersecting);
  };

  const getUserInfo = async (token: string) => {
    const response = (await Request.get(URLS.AUTH.userInfo, {
      headers: { Authorization: token },
    })) as any;
    if (response) {
      localStorage.setItem(
        Params.LOCAL_STORAGE.PATIENT_INFO,
        JSON.stringify(response.data)
      );
    } else {
      localStorage.removeItem(Params.LOCAL_STORAGE.PATIENT_INFO);
      removeCookie(Params.COOKIES.TOKEN);
      removeCookie(Params.COOKIES.TOKEN_REMOTE_PATIENT, {
        path: "/",
        domain: ".remotederm.ca",
        expires: new Date(new Date().setDate(new Date().getDate() - 1)),
      });
      setUser(null);
    }
  };

  async function logout() {
    setLoading(true);
    if (cookies[Params.COOKIES.TOKEN]) {
      const response = (await Request.delete(URLS.AUTH.logout, {
        headers: { Authorization: cookies[Params.COOKIES.TOKEN] },
      })) as any;
      if (response) {
        localStorage.removeItem(Params.LOCAL_STORAGE.PATIENT_INFO);
        removeCookie(Params.COOKIES.TOKEN);
        removeCookie(Params.COOKIES.TOKEN_REMOTE_PATIENT, {
          path: "/",
          domain: ".remotederm.ca",
          expires: new Date(new Date().setDate(new Date().getDate() - 1)),
        });
        setUser(null);
        props.setExpanded();
      }
    } else {
      localStorage.removeItem(Params.LOCAL_STORAGE.PATIENT_INFO);
      removeCookie(Params.COOKIES.TOKEN);
      removeCookie(Params.COOKIES.TOKEN_REMOTE_PATIENT, {
        path: "/",
        domain: ".remotederm.ca",
        expires: new Date(new Date().setDate(new Date().getDate() - 1)),
      });
      setUser(null);
      props.setExpanded();
    }
    setLoading(false);
  }

  return (
    <header ref={containerRef}>
      <div
        className={`z-20  flex border-b border-gray-100  items-center  absolute top-0  left-0 right-0 ${
          sticky ? "is-sticky" : "absolute"
        }`}
      >
        <div className="flex flex-col flex-1">
          {/* TODO:to remove valentine header comment code below */}
          {/* <BlackFriday /> */}
          {/* <Christmas /> */}
          {/* <Valentine /> */}
          {/* <Women /> */}
          {/* <Easter /> */}
          {/* <Mothers /> */}
          {/* <Summer /> */}
          <div className="flex py-4 lg:py-2.5 px-6 w-full lg:px-16 flex-row space-between max-w-screen-2xl mx-auto">
            <Link href="/">

              <img
                alt="RemoteDerm Logo"
                className="cursor-pointer lg:w-40 lg:h-12 w-36 mr-2 xl:mr-0 "
                src={"https://cdn.remotederm.ca/images/logo_text.webp"}
              />

            </Link>
            <Desktop user={user} loading={loading} onLogout={() => logout()} />
            <Mobile
              user={user}
              loading={loading}
              setExpanded={() => props.setExpanded()}
              onLogout={() => logout()}
              expanded={props.expanded}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
